<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder">
            {{ $t("Nation Groups") }}
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">
            {{ $t("List of nation's groups") }}
          </span>
        </div>
        <div>

          <b-button v-b-modal.modal-xl variant="light-primary" class="font-weight-bolder"
            @click="createItemClick('nation-group-modal')">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Home/Earth.svg" />
            </span>
            {{ $t("Add new group") }}
          </b-button>
          <b-button v-b-modal.modal-xl variant="light-primary" class="font-weight-bolder ml-3"
            @click="createItemClick('nation-group-existing-modal')">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Home/Earth.svg" />
            </span>
            {{ $t("Add to existing group") }}
          </b-button>
        </div>

      </div>
      <div class="card-body detail">
        <div v-if="group.length > 0">
          <b-table ref="datatableRef" :items="group" :fields="fields">

            <template #cell(id)="row">
              <i class="pointer" :class="[row.detailsShowing ? icons.caretUp : icons.caretDown]"
                @click="row.toggleDetails"></i>
            </template>

            <template #cell(highest)="data">
              <div class="ml-1">
                <div class="d-flex justify-content-start">
                  <div></div>
                  <div class="col-10">
                    {{ data.item.name }}
                  </div>
                  <div class="col-2">
                    <b-button v-b-tooltip :title="$t('Edit nation group')"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                      @click="editGroup('nation-group-modal', data.item)">
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                      </span>
                    </b-button>
                    <b-button v-b-tooltip :title="$t('Remove nation from group')"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" @click="removeNationGroup(data.item)">
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg src="/media/svg/icons/General/Trash.svg" />
                      </span>
                    </b-button>
                  </div>
                </div>
                <div class="col-12 text-muted font-size-sm">
                  {{ data.item.description }}
                </div>

              </div>
            </template>

            <template #row-details="row">
              <b-table :items="row.item.nationingroup_set" :fields="nestedFields">
                <template #cell(country)="{ item }">
                  <div v-if="item">
                    <CountryFlag :country-iso="item.country"></CountryFlag>
                  </div>
                </template>

                <template #cell(iso)="{ item }">
                  {{ item.country }}
                </template>

                <template #cell(adhesion_date)="{ item }">
                  <div class="d-flex align-items-center">
                    {{ item.adhesion_date }}
                  </div>
                </template>

              </b-table>
            </template>
          </b-table>
        </div>
        <div v-else>
          <p>{{ this.$t("The selected country is not part of any groups") }}.</p>
        </div>
        <NationGroupModal :existing-form="editForm" :countries="countryList" :nation-info="nation" @refresh="refresh">
        </NationGroupModal>
        <NationalGroupExistingModal :nation-info="nation" :group-choices="filteredGroups" @refresh="refresh">
        </NationalGroupExistingModal>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import CountryFlag from "@/view/components/CountryFlag.vue";
import Swal from "sweetalert2";
import { backendErrorSwal } from "@/core/helpers/swal";
import NationsGroupsService from "@/core/services/nation/nations-groups.service";
import NationGroupModal from "@/view/components/modals/nations/NationGroupModal.vue";
import NationalGroupExistingModal from "@/view/components/modals/nations/NationGroupExistingModal.vue";
import icons from "@/core/config/icons";
import { mapState, mapGetters } from "vuex";
export default ({
  components: {
    CountryFlag,
    NationGroupModal,
    NationalGroupExistingModal,
  },
  props: {
    nation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      group: [],
      icons,
      editForm: {},
      filteredGroups: [],
      fields: [
        { key: "id", label: "", thClass: "d-none" },
        { key: "highest", label: "", class: ["font-weight-bolder"], thClass: "d-none" },
      ],
      nestedFields: [
        { key: "country", label: `${this.$t("Country")}`, class: ["table-align", "font-weight-bolder"] },
        { key: "iso", label: `${this.$t("Iso")}`, class: ["table-align", "font-weight-bolder"] },
        { key: "adhesion_date", label: `${this.$t("Adhesion date")}`, class: ["table-align", "font-weight-bolder"] },
      ],
    }
  },
  computed: {
    ...mapState("constants", ["countries"]),
    ...mapGetters("constants", ["countriesMap"]),

    countryList() {
      return this.countries.map(el => ({ text: el.name, value: el.iso }));
    },
  },
  mounted() {
    this.loadSelectedNationGroups();
    this.isLoading = false;
  },
  methods: {
    async loadSelectedNationGroups() {
      await NationsGroupsService.getGroups().then(res => {
        let g = [];
        let f = [];
        res.results.forEach(el => {
          if (el.nationingroup_set.filter(element => element.country == this.nation.iso).length != 0) {
            g.push(el);
          } else {
            f.push(el);
          }
        });
        this.group = g;
        this.filteredGroups = f;
      });
    },
    refresh() {
      return this.loadSelectedNationGroups();
    },
    createItemClick(id) {
      this.editForm = {};
      this.$bvModal.show(id);
    },
    async editGroup(id, node) {
      this.editForm = { ...node };
      this.$bvModal.show(id);
    },
    async removeNationGroup(item) {
      Swal.fire({
        title: this.$t("Delete"),
        text: `${this.$t("Are you sure you want to remove the country from the group ")} ${item.name}?`,
        icon: "warning",
        confirmButtonClass: "btn btn-success",
        confirmButtonText: this.$t("Yes remove"),
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t("No, do NOT remove"),
        cancelButtonClass: "btn",
      }).then(async (res) => {
        if (res.isConfirmed) {
          this.isLoading = true;
          let body = item.nationingroup_set.filter(el => el.country != this.nation.iso);
          item.nationingroup_set = body;
          await NationsGroupsService.update(item.id, item)
            .then(() => {
              this.isLoading = false;
              this.loadSelectedNationGroups();
              Swal.fire(this.$t("Success"), this.$t("Country successfully removed from the group!"), "success");
            }).catch(err => {
              console.error(err);
              backendErrorSwal(err, err?.response?.data?.error);
            })
        }
      });
    },

  }

})
</script>