<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="nation-group-existing-modal" ref="upsertModal" size="xl" hide-footer @hidden="onUpsertModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <h4 class="mb-0">{{ $t("Add to Existing Nation Group") }}</h4>
          <div class="ml-5 d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t("Submit") }}
            </b-button>
            <b-button @click="closeUpsertModal">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </div>
      </template>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" :name="$t('Groups')" vid="group" rules="required">
          <euro-select v-model="form.selectedGroup" :label="`${$t('Groups')}*`" :error-messages="errors"
            :multiselect-props="groupsSelectProps" @input="$emit('update:group', $event)"></euro-select>
        </validation-provider>
      </div>
    </b-modal>
  </validation-observer>
</template>
<script>
import { getValidationErrors } from "@/core/helpers";
import Swal from "sweetalert2";
import NationsGroupsService from "@/core/services/nation/nations-groups.service";

export default {
  props: {
    nationInfo: {
      type: Object,
      default: null,
    },
    groupChoices: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isSaving: false,
      apiErrors: null,
      form: {
        selectedGroup: []
      }
    }
  },
  computed: {
    validationObserver() {
      return `validationObserver-${this._uid}`;
    },
    groupsSelectProps() {
      return {
        options: this.groupChoices,
        "track-by": "id",
        label: "name",
        multiple: true,
        "allow-empty": true,
        searchable: true,
      };
    },
  },
  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = getValidationErrors(val, this.form);
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    closeUpsertModal() {
      this.$bvModal.hide('nation-group-existing-modal');
    },

    onUpsertModalHidden() {
      this.isSaving = false;
    },
    async submit() {
      this.form.selectedGroup.forEach(async (group) => {
        let selected = this.groupChoices.filter(element => element.id == group)[0];
        selected.nationingroup_set.push({ id: this.nationInfo.id, country: this.nationInfo.iso, adhesion_date: null });
        await NationsGroupsService.update(group, selected).then(() => {
          this.closeUpsertModal();
          this.$emit('refresh');
          let title = this.$t("Added nation to the selected groups");
          Swal.fire(this.$t("Success"), title, "success");
        }).catch(err =>
          console.log(err))
      });
    },
  }
};

</script>