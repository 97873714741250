var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Nation Groups")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("List of nation's groups")) + " ")])]), _c('div', [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-xl",
      modifiers: {
        "modal-xl": true
      }
    }],
    staticClass: "font-weight-bolder",
    attrs: {
      "variant": "light-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.createItemClick('nation-group-modal');
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Home/Earth.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Add new group")) + " ")]), _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-xl",
      modifiers: {
        "modal-xl": true
      }
    }],
    staticClass: "font-weight-bolder ml-3",
    attrs: {
      "variant": "light-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.createItemClick('nation-group-existing-modal');
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Home/Earth.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Add to existing group")) + " ")])], 1)]), _c('div', {
    staticClass: "card-body detail"
  }, [_vm.group.length > 0 ? _c('div', [_c('b-table', {
    ref: "datatableRef",
    attrs: {
      "items": _vm.group,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(id)",
      fn: function fn(row) {
        return [_c('i', {
          staticClass: "pointer",
          class: [row.detailsShowing ? _vm.icons.caretUp : _vm.icons.caretDown],
          on: {
            "click": row.toggleDetails
          }
        })];
      }
    }, {
      key: "cell(highest)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "ml-1"
        }, [_c('div', {
          staticClass: "d-flex justify-content-start"
        }, [_c('div'), _c('div', {
          staticClass: "col-10"
        }, [_vm._v(" " + _vm._s(data.item.name) + " ")]), _c('div', {
          staticClass: "col-2"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('Edit nation group')
          },
          on: {
            "click": function click($event) {
              return _vm.editGroup('nation-group-modal', data.item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('Remove nation from group')
          },
          on: {
            "click": function click($event) {
              return _vm.removeNationGroup(data.item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1)]), _c('div', {
          staticClass: "col-12 text-muted font-size-sm"
        }, [_vm._v(" " + _vm._s(data.item.description) + " ")])])];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-table', {
          attrs: {
            "items": row.item.nationingroup_set,
            "fields": _vm.nestedFields
          },
          scopedSlots: _vm._u([{
            key: "cell(country)",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [item ? _c('div', [_c('CountryFlag', {
                attrs: {
                  "country-iso": item.country
                }
              })], 1) : _vm._e()];
            }
          }, {
            key: "cell(iso)",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_vm._v(" " + _vm._s(item.country) + " ")];
            }
          }, {
            key: "cell(adhesion_date)",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_vm._v(" " + _vm._s(item.adhesion_date) + " ")])];
            }
          }], null, true)
        })];
      }
    }], null, false, 3167227925)
  })], 1) : _c('div', [_c('p', [_vm._v(_vm._s(this.$t("The selected country is not part of any groups")) + ".")])]), _c('NationGroupModal', {
    attrs: {
      "existing-form": _vm.editForm,
      "countries": _vm.countryList,
      "nation-info": _vm.nation
    },
    on: {
      "refresh": _vm.refresh
    }
  }), _c('NationalGroupExistingModal', {
    attrs: {
      "nation-info": _vm.nation,
      "group-choices": _vm.filteredGroups
    },
    on: {
      "refresh": _vm.refresh
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }