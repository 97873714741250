var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-modal', {
          ref: "upsertModal",
          attrs: {
            "id": "nation-group-existing-modal",
            "size": "xl",
            "hide-footer": ""
          },
          on: {
            "hidden": _vm.onUpsertModalHidden
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "w-100 h-100 d-flex justify-content-between align-items-center"
              }, [_c('h4', {
                staticClass: "mb-0"
              }, [_vm._v(_vm._s(_vm.$t("Add to Existing Nation Group")))]), _c('div', {
                staticClass: "ml-5 d-flex align-items-center"
              }, [_c('b-button', {
                staticClass: "mr-2",
                class: {
                  'spinner spinner-light spinner-right': _vm.isSaving
                },
                attrs: {
                  "variant": "success"
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]), _c('b-button', {
                on: {
                  "click": _vm.closeUpsertModal
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Groups'),
            "vid": "group",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('euro-select', {
                attrs: {
                  "label": "".concat(_vm.$t('Groups'), "*"),
                  "error-messages": errors,
                  "multiselect-props": _vm.groupsSelectProps
                },
                on: {
                  "input": function input($event) {
                    return _vm.$emit('update:group', $event);
                  }
                },
                model: {
                  value: _vm.form.selectedGroup,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "selectedGroup", $$v);
                  },
                  expression: "form.selectedGroup"
                }
              })];
            }
          }], null, true)
        })], 1)])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }